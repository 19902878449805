
import { Options as Component, Vue } from "vue-class-component";

import PinInput from "@/components/PinInput.vue";
import SbBoard from "@/components/SB/board/SbBoard.vue";

@Component({
  name: "board",
  components: { PinInput, SbBoard }
})
export default class Board extends Vue {}
