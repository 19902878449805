import { reactive } from "vue";

import RemoteRTC from "@/core/rtc/RemoteRTC";
import Slot from "@/core/Slot";

export interface SlotInterface {
  uuid: string;
  x: number;
  y: number;
}

export class Application {
  public columns = 6;
  public rows = 3;
  public readonly RTC = new RemoteRTC();
  private slotsList = reactive(new Map<string, Slot>());

  constructor() {
    this.RTC.on("slotChanged", (slots: SlotInterface[]) => {
      slots.forEach(({ uuid, x, y }) => {
        const slot = this.slotsList.get(uuid);
        if (slot) {
          slot.move(x, y);
        } else {
          const slot = reactive(new Slot(uuid, x, y)) as Slot;
          slot.init();
          this.slotsList.set(uuid, slot);
        }
      });
      this.slotsList.forEach(slot => {
        if (!slots.find(s => s.uuid === slot.uuid))
          this.slotsList.delete(slot.uuid);
      });
    });
  }

  get slots() {
    return Array.from(this.slotsList.values());
  }
}

const app = reactive(new Application());
export default app as Application;
