import axios from "axios";
import EventEmitter from "events";
import SimplePeer from "simple-peer";

export default class RemoteRTC extends EventEmitter {
  private static SERVER = "studimax-cloud.ch:3333";
  private static SSL = true;
  private peer?: SimplePeer.Instance;
  private connected = false;

  public get isConnected() {
    return this.connected;
  }

  async connect(uuid: string): Promise<SimplePeer.Instance> {
    const { data } = await axios.get(
      (RemoteRTC.SSL ? "https://" : "http://") +
        RemoteRTC.SERVER +
        `/join/${uuid}`
    );
    const peer = (this.peer = new SimplePeer({
      trickle: false
    }));
    return new Promise(resolve => {
      peer.once("signal", async (signal: any) => {
        await axios.post(
          (RemoteRTC.SSL ? "https://" : "http://") +
            RemoteRTC.SERVER +
            `/join/${uuid}`,
          {
            signal
          }
        );
      });
      peer.on("data", data => {
        this.emit("message", data);
        try {
          const { channel, args } = JSON.parse(data.toString());
          console.log(channel, args);
          this.emit(channel, args);
        } catch (e) {
          console.log("Data parsing error", e);
        }
      });
      peer.once("connect", () => {
        this.connected = true;
        return resolve(peer);
      });
      peer.once("end", () => (this.connected = false));
      peer.signal(data.signal);
    });
  }

  public send(channel: string, ...args: any): boolean {
    if (!this.peer || !this.connected) return false;
    const data = JSON.stringify({ channel, args });
    console.log(data);
    this.peer.send(data);
    return true;
  }
}
