
import { Options as Component, Vue } from "vue-class-component";

import { Flipper } from "@/components/FLIP";
import { SbIcon, SbSlot } from "@/components/SB";
import Slot from "@/core/Slot";

@Component({
  name: "sb-board",
  components: { SbIcon, SbSlot, Flipper },
  props: ["col", "row", "slots"],
  watch: {
    slots: {
      handler() {
        this.flipKey++;
      },
      deep: true
    }
  }
})
export default class SbBoard extends Vue {
  private readonly col!: number;
  private readonly row!: number;
  private flipKey = 0;
  private readonly slots!: Slot[];

  getKey(i: number) {
    const x = i % this.col;
    const y = Math.floor(i / this.col);
    return [x, y].join(":");
  }

  getSlot(i: number) {
    const x = i % this.col;
    const y = Math.floor(i / this.col);
    return this.slots.find(s => s.x === x && s.y === y);
  }

  async drop(el: SbSlot, data: { type: string; payload: any }) {
    if (data.type === "slot") {
      const slot = this.slots.find(s => s.uuid === data.payload.uuid);
      if (slot) {
        slot.x = el.x;
        slot.y = el.y;
      }
    }
  }
}
