import "./registerServiceWorker";
import "@/assets/style.less";

import { createApp } from "vue";

import StreamBoard, { Application } from "@/core/Application";
import RemoteRTC from "@/core/rtc/RemoteRTC";

import App from "./App.vue";
import router from "./router";
import store from "./store";

const app = createApp(App);
app.use(store).use(router);

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $RTC: RemoteRTC;
    $SB: Application;
  }
}
app.config.globalProperties.$RTC = StreamBoard.RTC;
app.config.globalProperties.$SB = StreamBoard;
app.mount("#app");
(window as any).$RTC = StreamBoard.RTC;
