<template>
  <Flipped :key="`flip-${icon.uuid}`" :flip-id="`flip-${icon.uuid}`">
    <div class="sb-icon" @mousedown="down" @mouseup="up">
      <div :style="{ background: icon.color ?? 'black' }" class="container">
        <span :class="icon.align">
          <slot>{{ icon.text }}</slot>
        </span>
        <div
          v-if="icon.src"
          :style="{ backgroundImage: `url(${icon.src})` }"
          class="img"
        ></div>
      </div>
    </div>
  </Flipped>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";

import { Flipped } from "@/components/FLIP";
import Slot from "@/core/Slot";

@Component({
  name: "sb-icon",
  components: { Flipped },
  props: {
    icon: Slot
  }
})
export default class SbIcon extends Vue {
  private icon!: Slot;
  private pressDownAt = 0;

  down() {
    this.pressDownAt = Date.now();
    this.icon.send("pressDown");
  }

  up() {
    const pressDuration = Date.now() - this.pressDownAt;
    this.icon.send("pressUp", { pressDuration });
  }
}
</script>

<style lang="less">
.hover .sb-icon .container {
  box-shadow: 0 0 0 4px #7d81ba;
}

.sb-icon {
  display: flex;
  position: relative;
  width: 100%;

  &::before {
    content: "";
    display: inline-block;
    width: 1px;
    height: 0;
    padding-bottom: 100%;
  }

  .container {
    overflow: hidden;
    border-radius: var(--radius);
    cursor: pointer;
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: all linear 150ms;

    &:active {
      transform: scale(1.05);
    }

    .img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      z-index: 1;

      &::after {
        content: "";
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2));
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }

    span {
      z-index: 3;
      color: #d4dcff;
      font-weight: 700;
      font-size: 1.25em;
      margin: 0.5em;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      &.center {
        align-self: center;
      }

      &.up {
        align-self: flex-start;
      }

      &.down {
        align-self: flex-end;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
