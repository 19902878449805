
import { Options as Component, Vue } from "vue-class-component";

@Component({
  name: "pin-input",
  props: ["size"],
  emits: ["complete"],
  watch: {
    rawValue: function(rawValue) {
      this.update(rawValue);
    }
  }
})
export default class PinInput extends Vue {
  private size!: number;
  private rawValue = "";
  private focus = false;

  get length() {
    return this.rawValue ? this.rawValue.toString().length : 0;
  }

  onFocus() {
    this.focus = true;
    this.rawValue = "";
  }

  onBlur() {
    this.focus = false;
  }

  get value() {
    return this.rawValue
      .toUpperCase()
      .replace(/[^A-Z]/g, "")
      .slice(0, this.size);
  }

  update() {
    const value = this.value;
    if (this.rawValue === value) return;
    this.rawValue = value;
    if (this.length >= this.size) {
      this.rawValue = "";
      this.$emit("complete", value);
    }
  }
}
