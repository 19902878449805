<template>
  <div class="connection-container" v-if="!$RTC.isConnected">
    <div class="connection-alert">
      <div v-if="!loading">
        <pin-input :size="6" @complete="connect" />
      </div>
      <h1 v-else>
        LOADING
      </h1>
    </div>
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";

import PinInput from "@/components/PinInput.vue";

@Component({
  name: "connection",
  components: { PinInput }
})
export default class Connection extends Vue {
  private loading = false;

  connect(code: string) {
    this.loading = true;
    this.$RTC.connect(code).finally(() => (this.loading = false));
  }
}
</script>

<style scoped lang="less">
.connection-container {
  position: absolute;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 10;
  backdrop-filter: blur(5px) brightness(0.75);

  .connection-alert {
    display: block;
    width: 100%;
    text-align: center;
    max-width: 500px;
    background-color: var(--background);
    padding: 20px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25);
    margin: 20px;
    border-radius: 10px;
  }
}
</style>
