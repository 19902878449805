import { EventEmitter } from "events";

import app from "@/core/Application";

interface RequestData {
  event: string;
  ctx?: string;
  payload: any;
  requestUuid?: string;
}

export default class Slot {
  public text!: string;
  public color!: string;
  public src?: string;
  public align: "center" | "up" | "down" = "center";
  private readonly event = new EventEmitter();

  constructor(
    public readonly uuid: string,
    public x: number,
    public y: number
  ) {}

  public init() {
    this.on("setText", value => (this.text = value));
    this.on("setColor", value => (this.color = value));
    this.on("setImage", value => (this.src = value));
    app.RTC.on(this.uuid, ({ event, payload }: RequestData) => {
      this.event.emit(event, payload);
    });
  }

  public send(event: string, payload?: any) {
    app.RTC.send("emitContext", this.uuid, event, payload);
  }

  public on(event: string, listener: (payload: any) => void) {
    this.event.on(event, listener);
  }

  public move(x: number, y: number) {
    this.x = x;
    this.y = y;
  }
}
