<template>
  <connection />
  <router-view />
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";

import Connection from "@/components/Connection.vue";

@Component({
  components: { Connection }
})
export default class App extends Vue {
  toggleDarkMode(): boolean {
    return document.body.classList.toggle("theme-dark");
  }

  setDarkMode(value: boolean) {
    return value
      ? document.body.classList.add("theme-dark")
      : document.body.classList.remove("theme-dark");
  }

  mounted(): void {
    this.setDarkMode(true);
  }
}
</script>

<style lang="less"></style>
