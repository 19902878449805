
import { Options as Component, Vue } from "vue-class-component";

@Component({
  name: "sb-slot",
  emits: ["drop"],
  props: {
    round: Boolean,
    slotContent: Object
  }
})
export default class SbSlot extends Vue {
  private hover = false;
  private round!: boolean;
  private slotContent!: any;

  public get x() {
    return parseInt(this.$.vnode.key?.toString().split(":")[0] ?? "-1");
  }

  public get y() {
    return parseInt(this.$.vnode.key?.toString().split(":")[1] ?? "-1");
  }
}
