<template>
  <div class="sb-slot" ref="slot" :class="{ hover, round }">
    <slot v-bind:id="$.vnode.key" v-bind:slot-content="slotContent" />
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";

@Component({
  name: "sb-slot",
  emits: ["drop"],
  props: {
    round: Boolean,
    slotContent: Object
  }
})
export default class SbSlot extends Vue {
  private hover = false;
  private round!: boolean;
  private slotContent!: any;

  public get x() {
    return parseInt(this.$.vnode.key?.toString().split(":")[0] ?? "-1");
  }

  public get y() {
    return parseInt(this.$.vnode.key?.toString().split(":")[1] ?? "-1");
  }
}
</script>

<style lang="less">
.sb-slot {
  --radius: 15%;
  --shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  --border: inset 0 0 0 3px rgba(70, 70, 140, 0.5);

  &.round {
    --radius: 50% !important;
  }

  display: flex;
  border-radius: var(--radius);
  box-shadow: var(--shadow), var(--border);
  background: var(--card-bg);
  transition: border-radius, box-shadow linear 150ms;

  &.hover {
    --border: inset 0 0 0 4px #7d81ba;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    padding-bottom: 100%;
  }
}
</style>
