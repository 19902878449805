<template>
  <main>
    <sb-board :slots="$SB.slots" :col="$SB.columns" :row="$SB.rows" />
  </main>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";

import PinInput from "@/components/PinInput.vue";
import SbBoard from "@/components/SB/board/SbBoard.vue";

@Component({
  name: "board",
  components: { PinInput, SbBoard }
})
export default class Board extends Vue {}
</script>

<style scoped lang="less">
main {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
</style>
