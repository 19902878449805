
import { Options as Component, Vue } from "vue-class-component";

import { Flipped } from "@/components/FLIP";
import Slot from "@/core/Slot";

@Component({
  name: "sb-icon",
  components: { Flipped },
  props: {
    icon: Slot
  }
})
export default class SbIcon extends Vue {
  private icon!: Slot;
  private pressDownAt = 0;

  down() {
    this.pressDownAt = Date.now();
    this.icon.send("pressDown");
  }

  up() {
    const pressDuration = Date.now() - this.pressDownAt;
    this.icon.send("pressUp", { pressDuration });
  }
}
