<template>
  <div class="pin-digit-container">
    <div
      @click="$refs.input.focus()"
      v-for="i in size"
      :key="i"
      class="pin-digit"
      :class="{ current: i - 1 === length && focus, filled: i - 1 < length }"
      :data-content="value[i - 1]"
    ></div>
    <input
      type="text"
      class="pin-input"
      ref="input"
      v-model="rawValue"
      @focus="onFocus"
      @blur="onBlur"
    />
  </div>
</template>

<script lang="ts">
import { Options as Component, Vue } from "vue-class-component";

@Component({
  name: "pin-input",
  props: ["size"],
  emits: ["complete"],
  watch: {
    rawValue: function(rawValue) {
      this.update(rawValue);
    }
  }
})
export default class PinInput extends Vue {
  private size!: number;
  private rawValue = "";
  private focus = false;

  get length() {
    return this.rawValue ? this.rawValue.toString().length : 0;
  }

  onFocus() {
    this.focus = true;
    this.rawValue = "";
  }

  onBlur() {
    this.focus = false;
  }

  get value() {
    return this.rawValue
      .toUpperCase()
      .replace(/[^A-Z]/g, "")
      .slice(0, this.size);
  }

  update() {
    const value = this.value;
    if (this.rawValue === value) return;
    this.rawValue = value;
    if (this.length >= this.size) {
      this.rawValue = "";
      this.$emit("complete", value);
    }
  }
}
</script>

<style scoped lang="less">
.pin-digit-container {
  display: flex;

  .pin-digit:first-child {
    margin-left: 0 !important;
  }

  .pin-digit {
    --shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    --border: inset 0 0 0 3px rgba(70, 70, 140, 0.5);
    float: left;
    margin-left: 4%;
    width: 100%;
    height: 90px;
    line-height: 90px;
    font-size: 64px;
    border-radius: 5px;
    cursor: text;
    box-shadow: var(--shadow), var(--border);
    background: var(--card-bg);
    text-align: center;
    transition: all 100ms;

    &.current {
      background-color: #fff;
      color: #323232;
      transform: scale(1.1);
    }

    &.filled::after {
      content: attr(data-content);
    }
  }
}

.pin-input {
  position: absolute;
  top: -1000px;
  width: 390px;
  font-size: 72px;
}
</style>
