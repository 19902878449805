
import { Options as Component, Vue } from "vue-class-component";

import PinInput from "@/components/PinInput.vue";

@Component({
  name: "connection",
  components: { PinInput }
})
export default class Connection extends Vue {
  private loading = false;

  connect(code: string) {
    this.loading = true;
    this.$RTC.connect(code).finally(() => (this.loading = false));
  }
}
